import React from "react"
import { motion } from "framer-motion"

const Button = ({ children, secondary, style }) => {
  return (
    <motion.div
      style={{
        ...styles.button,
        ...(secondary ? styles.secondary : styles.primary),
        ...(style ? style : {}),
      }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {children}
    </motion.div>
  )
}

export default Button

const styles = {
  button: {
    color: "#ebf5ff",
    padding: 20,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 10,
    cursor: "pointer",
    textAlign: "center",
  },
  primary: {
    backgroundColor: "#000",
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 2,
  },
  secondary: {
    color: "#000",
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 2,
  },
}
