import React, { useState } from "react"
import colors from "../constants/colors"
import Button from "../components/Button"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import FlexV from "../components/Flex/FlexV"
import FlexH from "../components/Flex/FlexH"
import SectionV from "../components/Flex/SectionV"
import Divider from "../components/Divider"
import { motion } from "framer-motion"

import {
  FaMicrophone,
  MdAccountBalance,
  FaRegPlayCircle,
  HiOutlineChevronDown,
} from "react-icons/all"

import "react-modal-video/scss/modal-video.scss"
import ModalVideo from "react-modal-video"
import scrollTo from "gatsby-plugin-smoothscroll"

export default function Home() {
  return (
    <Layout>
      <SEO
        title="Get the app"
        description="Create memes of your friends or idols using AI!"
      />

      <SectionV style={styles.main}>
        <FlexH>
          <div>
            <motion.img
              whileHover={{ y: -10 }}
              transition={{ type: "spring", stiffness: 150 }}
              src="phone_preview.png"
              alt="Litso Phone demo"
            />
          </div>

          <FlexV style={styles.mainSection}>
            <h1 style={styles.appName}>Litso APP</h1>
            <h1 style={styles.headline}>
              Face filters using{" "}
              <span style={styles.accent}>Artificial Intelligence</span> right
              from your phone.
            </h1>
            <FlexH style={styles.ctaButtons}>
              <a
                href="https://www.litso.io/get"
                target="_blank"
                rel="noreferrer"
              >
                <Button style={styles.ctaButton}>
                  <img
                    style={styles.ctaButtonImg}
                    src="apple.svg"
                    alt="AppStore"
                  />
                  Download the app!
                </Button>
              </a>
            </FlexH>
          </FlexV>
        </FlexH>

        <HiOutlineChevronDown
          size={60}
          onClick={() => scrollTo("#demo")}
          style={styles.arrowDown}
        />
      </SectionV>

      <Demo />

      <Divider />

      <LitsoGuide />

      <Partners />
    </Layout>
  )
}

let styles = {
  main: {
    marginTop: 250,
    marginBottom: 300,
    flexWrap: "wrap",
    position: "relative",
  },
  mainSection: {
    color: "#fff",
  },
  appName: {
    marginBottom: 10,
  },
  headline: {
    marginTop: 5,
    marginBottom: 5,
    maxWidth: 450,
    textAlign: "center",
  },
  ctaButtons: {
    marginTop: 15,
    flexWrap: "wrap",
  },
  ctaButton: {
    width: 180,
    marginRight: 10,
    marginBottom: 10,
  },
  ctaButtonImg: {
    marginRight: 10,
  },
  accent: {
    color: colors.accentPurple,
  },
  arrowDown: {
    position: "absolute",
    bottom: -200,
    color: "#545454",
    cursor: "pointer",
  },
}

const Demo = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <SectionV id="demo" style={styles.demo}>
      <div style={styles.demoLogo}>
        <img
          style={styles.demoLogoImg}
          src="litso_app.svg"
          alt="Litso APP logo"
        />
      </div>

      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="EpBARaBc4y0"
        onClose={() => setOpen(false)}
      />

      <FlexV>
        <h1 style={styles.demoHeadline}>
          bring portraits <span style={styles.demoAccent}>to life</span>
        </h1>
        <video autoPlay loop muted playsInline style={styles.demoAnim}>
          <source src="res/anim.webm" type="video/webm" />
          <source src="res/anim.mp4" type="video/mp4" />
        </video>
      </FlexV>
    </SectionV>
  )
}

styles = {
  ...styles,
  demo: {
    boxShadow: "rgba(220, 220, 220, 0.6) 0 1px inset",
    backgroundColor: "#1d1e22",
    paddingTop: 80,
    paddingBottom: 100,
  },
  demoLogo: {
    marginBottom: 50,
  },
  demoLogoImg: {
    height: 75,
  },
  demoHeadline: {
    maxWidth: 500,
    textAlign: "center",
    color: "#f6f6f6",
  },
  demoAnim: {
    width: 400,
    maxWidth: "100%",
    borderRadius: 20,
    marginBottom: 20,
    position: "relative",
  },
  demoAccent: {
    color: "#ad5ffa",
  },
  demoPlayIcon: {
    position: "absolute",
    top: 200 - 75 / 2,
    left: 200 - 75 / 2,
  },
}

const LitsoGuide = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <SectionV style={styles.guide}>
      <div style={styles.guideLogo}>
        <img
          style={styles.guideLogoImg}
          src="litso_guide.svg"
          alt="Litso Guide logo"
        />
      </div>

      <FlexH style={{ flexWrap: "wrap" }}>
        <div style={styles.guideDemo}>
          <img
            style={styles.guideDemoImg}
            src="/res/louis_14_demo.jpg"
            alt="Litso Guide demo"
          />
        </div>
        <FlexV>
          <h1 style={styles.guideHeadline}>
            A more <span style={styles.guideAccent}>immersive</span> audioguide
            experience
          </h1>

          <div style={styles.guideFeatureRow}>
            <FlexH>
              <FaMicrophone size={75} style={styles.guideFeatureIcon} />
              <h2 style={styles.guideFeature}>
                Narrated by the portrait itself
              </h2>
            </FlexH>
          </div>

          <div style={styles.guideFeatureRow}>
            <FlexH>
              <MdAccountBalance size={75} style={styles.guideFeatureIcon} />
              <h2 style={styles.guideFeature}>More engaged visitors</h2>
            </FlexH>
          </div>

          <div style={styles.guideCTA}>
            <a href="mailto:alex@litso.io?subject=Litso Guide">
              <Button>Get in touch or request a demo</Button>
            </a>
          </div>
        </FlexV>
      </FlexH>

      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="9A4e8cNfmsU"
        onClose={() => setOpen(false)}
      />

      <Divider style={{ marginTop: 50, marginBottom: 50 }} />

      <FlexH>
        <FlexV>
          <h1 style={styles.guideDemoHeadline}>
            See it <span style={styles.guideAccent}>in action</span>
          </h1>
          <img style={styles.guideDemoArrow} src="demo_arrow.svg" alt="arrow" />
        </FlexV>

        <motion.div
          onClick={() => setOpen(true)}
          whileHover={{ scale: 1.05 }}
          style={{ position: "relative", cursor: "pointer" }}
        >
          <video autoPlay loop muted playsInline style={styles.guideDemoAnim}>
            <source src="res/litso_guide.webm" type="video/webm" />
            <source src="res/litso_guide.mp4" type="video/mp4" />
          </video>
          <FaRegPlayCircle
            size={75}
            color="white"
            style={styles.guideDemoPlayIcon}
          />
        </motion.div>
      </FlexH>
    </SectionV>
  )
}

styles = {
  ...styles,
  guide: {
    backgroundColor: "#fff",
    paddingTop: 75,
    paddingBottom: 100,
  },
  guideLogo: {
    marginBottom: 50,
  },
  guideLogoImg: {
    height: 75,
  },
  guideDemo: {
    width: 350,
    height: 300,
    overflow: "hidden",
    borderRadius: 30,
    marginRight: 20,
  },
  guideHeadline: {
    maxWidth: 450,
    textAlign: "center",
  },
  guideDemoImg: {
    height: "100%",
  },
  guideFeatureRow: {
    marginBottom: 30,
  },
  guideFeature: {
    width: 200,
    textAlign: "center",
  },
  guideFeatureIcon: {
    color: "#ac946a",
  },
  guideAccent: {
    color: "#935acf",
  },
  guideCTA: {
    marginTop: 10,
  },
  guideDemoHeadline: {
    marginLeft: 50,
    marginRight: 50,
  },
  guideDemoAnim: {
    height: 400,
    maxWidth: "100%",
    borderRadius: 10,
    marginBottom: 20,
    position: "relative",
  },
  guideDemoPlayIcon: {
    position: "absolute",
    top: 200 - 75 / 2,
    left: 113 - 75 / 2,
  },
  guideDemoArrow: {
    width: 150,
    marginTop: -50,
  },
}

const Partners = () => {
  return (
    <SectionV style={styles.partners}>
      <p style={styles.trusted}>TRUSTED BY</p>
      <FlexH style={styles.partnerLogos}>
        <img
          style={{ ...styles.partnerLogo, height: 30 }}
          src="partners/epfl_logo.svg"
          alt="EPFL"
        />
        <img
          style={{ ...styles.partnerLogo, height: 30 }}
          src="partners/ethz_logo.svg"
          alt="ETH Zurich"
        />
      </FlexH>
    </SectionV>
  )
}

styles = {
  ...styles,
  partners: {
    backgroundColor: "#d9d9d9",
    paddingTop: 75,
    paddingBottom: 75,
  },
  trusted: {
    color: "#737492",
  },
  partnerLogos: {
    flexWrap: "wrap",
    maxWidth: 680,
  },
  partnerLogo: {
    margin: 25,
    height: 40,
  },
}
